const wlConfig = {
    Auth: {
        mandatorySignIn: true,
        region: "us-east-1",
        userPoolId: "us-east-1_NV5lgzG7J",
        identityPoolId:"us-east-1:b38209ac-4d48-475d-a621-844f843512b6",
        userPoolWebClientId: "jpcvapbniaql74g3u5p8dcbd1"
    },
    Storage: {
        region: "us-east-1",
        bucket: "wl-automation-dev-document-repo",
        identityPoolId: "us-east-1:b38209ac-4d48-475d-a621-844f843512b6"
    },
    oauth: {
        domain: "wl-qa-logostore.auth.us-east-1.amazoncognito.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn:"https://web-app.wl-qa-logostore.com/login",
        redirectSignOut: "https://web-app.wl-qa-logostore.com/login",
        // redirectSignIn:"http://localhost/login",
        // redirectSignOut: "http://localhost/login",
        responseType: "code",
        clientId: "jpcvapbniaql74g3u5p8dcbd1"

      }
}
export default wlConfig;