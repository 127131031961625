import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./Components/ProtectedRoutes";
import Home from "./Components/Home/Home";
import Login from "./Components/Login/Login";
// import Signup from "./Components/Signup/Signup";
import wlConfig from './wlConfig'
import { Amplify } from "aws-amplify";
import TokenGenerator from "./Components/TokenGenerator/TokenGenerator";


Amplify.configure(wlConfig)

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            {/* <Route path="signup" element={<Signup />} /> */}
            <Route element={<ProtectedRoute />}>
              <Route path="get-token" element={<TokenGenerator />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
