import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './TokenGenerator.css'
import { TextField, Stack, Button, Alert } from '@mui/material';
import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from "react-router-dom";


function Copyright(props) {
    return (
        <Typography variant="body2" color="white" align="left" {...props}>
            {'Copyright © '}
            {new Date().getFullYear()}
            {' Williams Lea. All rights reserved.'}
        </Typography>
    );
}


const TokenGenerator = () => {

    const [open, setOpen] = useState(false);
    const [tokens, setTokens] = useState({})
    const [userData, setUserData] = useState({})
    const navigate = useNavigate()


    async function signOut() {
        try {
            await Auth.signOut();
            navigate('/login')
            localStorage.clear();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    async function getTokens() {
        const user = await Auth.currentAuthenticatedUser()
        const idToken = user.signInUserSession.idToken.jwtToken
        const accessToken = user.signInUserSession.accessToken.jwtToken
        const refreshToken = user.signInUserSession.refreshToken.token
        let lsUserData = JSON.parse(localStorage.getItem("userData"))
        var d = new Date(0);
        var c = new Date(0);
        d.setUTCSeconds(lsUserData.iat);
        c.setUTCSeconds(lsUserData.exp);
        lsUserData = {...lsUserData, "iat":d.toLocaleString(),"exp":c.toLocaleString()}
        setUserData(lsUserData)
        setTokens({ idToken, accessToken, refreshToken })
    }

    useEffect(() => {
        getTokens()
    }, [])

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" style={{ background: '#008080' }}>
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Williams Lea LogoStore Sample App
                        </Typography>
                        <Button onClick={() => signOut()} color="inherit">Logout</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <br />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 5, mr: 5, mb: 2 }}>
                Token Generator
            </Typography>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Token Copied to Clipboard...!
                </Alert>
            </Snackbar>
            <Stack sx={{ ml: 5, mr: 5 }} spacing={2}>
                <Stack direction="row" spacing={1}>
                    <Typography sx={{ minWidth: 200 }} variant="body1" color="primary">User Name : </Typography>
                    <Typography variant="body1" color="initial">{userData.name} </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Typography sx={{ minWidth: 200 }} variant="body1" color="primary">Email : </Typography>
                    <Typography variant="body1" color="initial">{userData.email} </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Typography sx={{ minWidth: 200 }} variant="body1" color="primary">Token Issue Time : </Typography>
                    <Typography variant="body1" color="initial">{userData.iat} (Local Time) </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Typography sx={{ minWidth: 200 }} variant="body1" color="primary">Token Expiry Time : </Typography>
                    <Typography variant="body1" color="initial">{userData.exp} (Local Time) </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <TextField
                        id="outlined-multiline-static"
                        label="ID Token"
                        multiline
                        rows={7}
                        defaultValue={tokens.idToken}
                        className='TokenBoxes'
                        fullWidth
                        disabled
                    />
                    <Button onClick={() => {
                        handleClick()
                        navigator.clipboard.writeText(tokens.idToken)
                    }} variant="text" sx={{ minWidth: 200 }} startIcon={<ContentCopyIcon />}>
                        Copy ID Token
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Access Token"
                        multiline
                        rows={6}
                        defaultValue={tokens.accessToken}
                        className='TokenBoxes'
                        fullWidth
                        disabled
                    />
                    <Button onClick={() => {
                        handleClick()
                        navigator.clipboard.writeText(tokens.accessToken)
                    }} variant="text" sx={{ minWidth: 200 }} startIcon={<ContentCopyIcon />}>
                        Copy Access Token
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Refresh Token"
                        multiline
                        rows={8}
                        defaultValue={tokens.refreshToken}
                        className='TokenBoxes'
                        fullWidth
                        disabled
                    />
                    <Button onClick={() => {
                        handleClick()
                        navigator.clipboard.writeText(tokens.refreshToken)
                    }} variant="text" sx={{ minWidth: 200 }} startIcon={<ContentCopyIcon />}>
                        Copy Refresh Token
                    </Button>
                </Stack>
            </Stack>
            <div className="footer">
                <Copyright className="FooterText" />
            </div>
        </div>
    )
}

export default TokenGenerator