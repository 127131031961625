import React from 'react'

const WlLogo = () => {
  return (
    <svg id="Group_65" data-name="Group 65" xmlns="http://www.w3.org/2000/svg" width="187.303" height="24" viewBox="0 0 187.303 24">
  <path id="Path_59" data-name="Path 59" d="M549.822,79.826a9.1,9.1,0,0,1,8.56,8.486c-2.366-.123-5.872-.71-6.839-1.672s-1.578-4.457-1.721-6.814" transform="translate(-540.093 -74.088)" fill="#007c82"/>
  <path id="Path_60" data-name="Path 60" d="M558.332,85.212a9.1,9.1,0,0,1-8.486,8.558c.122-2.364.712-5.87,1.674-6.837s4.455-1.578,6.812-1.721" transform="translate(-540.074 -69.77)" fill="#007c82"/>
  <path id="Path_61" data-name="Path 61" d="M552.982,93.733a9.1,9.1,0,0,1-8.56-8.486c2.366.124,5.87.71,6.837,1.674s1.578,4.456,1.722,6.812" transform="translate(-544.422 -69.742)" fill="#007c82"/>
  <path id="Path_62" data-name="Path 62" d="M544.437,88.385a9.1,9.1,0,0,1,8.486-8.56c-.124,2.366-.71,5.872-1.674,6.837s-4.456,1.578-6.812,1.722" transform="translate(-544.41 -74.089)" fill="#007c82"/>
  <path id="Path_63" data-name="Path 63" d="M558,80.289h2.992l4.567,13.482,4.5-13.482h2.993L577.6,93.771l4.5-13.482h2.962L579.046,96.61h-2.995l-4.5-12.835-4.5,12.835h-2.992Z" transform="translate(-533.535 -73.717)" fill="#333d47"/>
  <path id="Path_64" data-name="Path 64" d="M576.981,77.2a1.979,1.979,0,0,1,0,2.652,1.825,1.825,0,0,1-2.56,0,1.979,1.979,0,0,1,0-2.652,1.825,1.825,0,0,1,2.56,0m-2.7,5.985h2.87V99.507h-2.87Z" transform="translate(-520.781 -76.613)" fill="#333d47"/>
  <rect id="Rectangle_22" data-name="Rectangle 22" width="2.87" height="22.894" transform="translate(60.734)" fill="#333d47"/>
  <rect id="Rectangle_23" data-name="Rectangle 23" width="2.868" height="22.894" transform="translate(68.053)" fill="#333d47"/>
  <path id="Path_65" data-name="Path 65" d="M589.092,77.2a1.985,1.985,0,0,1,0,2.652,1.827,1.827,0,0,1-2.562,0,1.984,1.984,0,0,1,0-2.652,1.827,1.827,0,0,1,2.562,0m-2.7,5.985h2.868V99.507h-2.868Z" transform="translate(-511.073 -76.613)" fill="#333d47"/>
  <path id="Path_66" data-name="Path 66" d="M600.855,96.651V94.368q-1.824,2.408-5.71,2.407a6.529,6.529,0,0,1-2.976-.647,4.778,4.778,0,0,1-1.975-1.791,4.827,4.827,0,0,1-.7-2.56,4.193,4.193,0,0,1,1.62-3.472,7.257,7.257,0,0,1,4.52-1.31h5.183v-.773a3.372,3.372,0,0,0-1.063-2.668,4.551,4.551,0,0,0-3.1-.942,8.892,8.892,0,0,0-5.03,1.758l-1.2-1.973a15.026,15.026,0,0,1,3.256-1.652,11.127,11.127,0,0,1,3.564-.508,6.822,6.822,0,0,1,4.72,1.5,5.42,5.42,0,0,1,1.7,4.18l.031,10.736Zm-1.713-3.223a3.82,3.82,0,0,0,1.681-2.576V89.218h-4.782a5.587,5.587,0,0,0-2.886.586,2.023,2.023,0,0,0-.939,1.85,2.4,2.4,0,0,0,.957,2.005,4.068,4.068,0,0,0,2.56.74,5.854,5.854,0,0,0,3.409-.971" transform="translate(-508.285 -73.757)" fill="#333d47"/>
  <path id="Path_67" data-name="Path 67" d="M625.078,81.935a6.461,6.461,0,0,1,1.605,4.628V96.651h-2.9V87.333a4.479,4.479,0,0,0-1.126-3.239,4.1,4.1,0,0,0-3.1-1.171,4.68,4.68,0,0,0-3.643,1.636,6.048,6.048,0,0,0-1.328,4.039v8.053h-2.9V87.333a4.52,4.52,0,0,0-1.113-3.239,4.085,4.085,0,0,0-3.117-1.171,4.719,4.719,0,0,0-3.652,1.636,5.991,5.991,0,0,0-1.342,4.039v8.053H599.59V80.33h2.874v3.425q1.6-3.454,6.075-3.517a6.24,6.24,0,0,1,3.748,1.079,5.274,5.274,0,0,1,2.021,3.025q1.513-4.043,6.358-4.1a5.786,5.786,0,0,1,4.412,1.7" transform="translate(-500.195 -73.757)" fill="#333d47"/>
  <path id="Path_68" data-name="Path 68" d="M624.674,82.967a8.015,8.015,0,0,0-2.33-.371,3.971,3.971,0,0,0-2.081.479,1.6,1.6,0,0,0-.787,1.467,1.63,1.63,0,0,0,.879,1.5,12.706,12.706,0,0,0,2.668,1.032,20.983,20.983,0,0,1,2.776.989,4.925,4.925,0,0,1,1.836,1.449,4.492,4.492,0,0,1-.987,6.048,7.455,7.455,0,0,1-4.335,1.2,10.784,10.784,0,0,1-3.533-.587,8.008,8.008,0,0,1-2.885-1.665l1.081-2.1a7.6,7.6,0,0,0,2.513,1.481,8.337,8.337,0,0,0,2.917.557,4.351,4.351,0,0,0,2.283-.526,1.749,1.749,0,0,0,.865-1.6,1.827,1.827,0,0,0-.912-1.667,12.405,12.405,0,0,0-2.762-1.11,18.482,18.482,0,0,1-2.668-.94,4.722,4.722,0,0,1-1.758-1.405,3.778,3.778,0,0,1-.724-2.407,3.878,3.878,0,0,1,1.665-3.425,7.226,7.226,0,0,1,4.165-1.14,10.413,10.413,0,0,1,2.915.416,8.856,8.856,0,0,1,2.484,1.126l-1.048,2.191a9.822,9.822,0,0,0-2.238-.987" transform="translate(-487.123 -73.771)" fill="#333d47"/>
  <rect id="Rectangle_24" data-name="Rectangle 24" width="2.87" height="22.894" transform="translate(149.474)" fill="#007c82"/>
  <path id="Path_69" data-name="Path 69" d="M644.63,82.474a9.174,9.174,0,0,1,1.942,6.218c0,.413-.011.721-.029.926h-12.9a5.564,5.564,0,0,0,1.836,3.486,5.439,5.439,0,0,0,3.686,1.3,6.907,6.907,0,0,0,2.7-.524,6.478,6.478,0,0,0,2.175-1.481l1.573,1.636a8.213,8.213,0,0,1-2.9,2.005,9.649,9.649,0,0,1-3.764.71,8.663,8.663,0,0,1-4.274-1.034,7.274,7.274,0,0,1-2.9-2.915,8.751,8.751,0,0,1-1.032-4.29,8.657,8.657,0,0,1,1.032-4.272,7.532,7.532,0,0,1,2.885-2.931,8.33,8.33,0,0,1,4.229-1.065,7.2,7.2,0,0,1,5.738,2.236m-.649,4.922a4.73,4.73,0,0,0-5.059-4.783,5.162,5.162,0,0,0-3.594,1.3,5.442,5.442,0,0,0-1.713,3.488Z" transform="translate(-475.219 -73.757)" fill="#007c82"/>
  <path id="Path_70" data-name="Path 70" d="M651.861,96.651V94.368q-1.822,2.408-5.709,2.407a6.524,6.524,0,0,1-2.976-.647,4.738,4.738,0,0,1-2.67-4.351,4.193,4.193,0,0,1,1.62-3.472A7.258,7.258,0,0,1,646.645,87h5.183v-.773a3.372,3.372,0,0,0-1.063-2.668,4.551,4.551,0,0,0-3.1-.942,8.893,8.893,0,0,0-5.03,1.758l-1.2-1.973a15.026,15.026,0,0,1,3.256-1.652,11.127,11.127,0,0,1,3.564-.508,6.821,6.821,0,0,1,4.72,1.5,5.427,5.427,0,0,1,1.7,4.18l.031,10.736Zm-1.713-3.223a3.82,3.82,0,0,0,1.681-2.576V89.218h-4.782a5.587,5.587,0,0,0-2.886.586,2.023,2.023,0,0,0-.939,1.85,2.4,2.4,0,0,0,.957,2.005,4.068,4.068,0,0,0,2.56.74,5.85,5.85,0,0,0,3.409-.971" transform="translate(-467.395 -73.757)" fill="#007c82"/>
</svg>
  )
}

export default WlLogo