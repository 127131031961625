import React from 'react'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Home = () => {
  return (
    <div>
      <Link href="/login">
        <Typography variant="h5" component="h2">
          Navigate to login Page
        </Typography>
      </Link>
    </div>
  )
}

export default Home